import gql from "graphql-tag";

export const GET_GOOROO_PASS_SUBSCRIPTION = gql`
  query GetGoorooPassSubscription {
    subscription: passSubscription {
      id
      isActive
    }
  }
`;

export const GET_GOOROO_PASS_PRICING = gql`
  query GetGoorooPassPricing {
    pricing: passPricing(platform: WEB) {
      trialPeriodDays
      price
      isEligibleForTrial
    }
  }
`;

export const GET_GOOROO_PASS_CONFIRMATION_DATA = gql`
  query GetGoorooPassConfirmationData {
    pricing: passPricing(platform: WEB) {
      price
    }
    nextBill: passNextBill {
      date
      amount
    },
    subscription: passSubscription {
      id
      status
      trialEndsAt
      onTrial
    }
  }
`;

export const CREATE_GOOROO_PASS_QUOTE = gql`
  mutation CreateGoorooPassQuote($promoCode: String) {
    result: createGoorooPassQuote(promoCode: $promoCode) {
      quote {
        token
        dueToday
        lineItems {
          title
          amount
        }
        discount {
          title
          amount
        }
        trialDetails {
          endsAt
          firstChargeAmount
        }
        nextCharge {
          date
          amount
        }
      }
    }
  }
`;
