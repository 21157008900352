<template>
  <header class="goo-header" :class="className">
    <div class="goo-header-nav">
      <nav class="goo-header-logo">
        <slot name="logo">
          <a :href="logoUrl" class="goo-logo is-desktop" @click="onLogoClick">
            <slot name="desktop-logo">
              <goo-icon name="logo" class="icon" :width="compact ? 90 : 150" />
            </slot>
            <slot name="title">
              <div v-if="title" class="goo-header-title">
                <span class="has-text-bold">{{ title }}</span>
              </div>
            </slot>
          </a>
          <template v-if="!hasMobileNav">
            <a :href="logoUrl" class="goo-logo is-mobile" @click="onLogoClick">
              <slot name="mobile-logo">
                <goo-icon name="logo-stamp" class="icon" :width="compact ? 24 : 40" />
              </slot>
            </a>
            <slot v-if="partnership" name="partnership">
              <div class="is-gray-extra-dark-text-color mx-2">
                X
              </div>
              <img src="@/assets/auth/itza_logo.png" width="65px">
            </slot>
          </template>
          <template v-if="hasMobileNav">
            <button
              type="button"
              class="hide-large-up goo-header-nav-toggle icon-button is-mobile goo-logo"
              @click="onNavToggleClick"
            >
              <slot name="mobile-logo">
                <goo-icon name="logo-stamp" class="icon" :width="compact ? 24 : 40" />
              </slot>
              <goo-icon name="chevron-down" width="24" class="goo-header-nav-toggle-chevron icon" />
              <span v-if="!mobileDropDownActive" class="is-sr-only">Open Navigation</span>
              <span v-if="mobileDropDownActive" class="is-sr-only">Close Navigation</span>
            </button>
          </template>
        </slot>
      </nav>
      <div v-if="partnership" class="goo-header-middle heading-2 is-dark-text-color my-2 hide-large-down">
        Redirecting to Gooroo Clubs
      </div>
      <div class="goo-header-main">
        <slot name="left" :isTransparent="isTransparent" :mobileDropDownActive="mobileDropDownActive" />
        <slot name="center" :isTransparent="isTransparent" :mobileDropDownActive="mobileDropDownActive" />
        <slot name="right" :isTransparent="isTransparent" :mobileDropDownActive="mobileDropDownActive" />
        <slot name="cta" :isTransparent="isTransparent" :mobileDropDownActive="mobileDropDownActive" />
      </div>
      <transition name="goo-header-mobile-nav-dropdown" @afterLeave="onAfterDropDownClose">
        <div v-if="mobileDropDownActive" class="goo-header-mobile-nav-dropdown">
          <nav ref="mobileNav" class="goo-header-mobile-nav">
            <slot
              name="mobile-nav"
              :isTransparent="isTransparent"
              :onNavToggleClick="onNavToggleClick"
            />
          </nav>
        </div>
      </transition>
    </div>
    <slot
      name="bottom"
      :isTransparent="isTransparent"
    />
  </header>
</template>

<script>
  export default {
    name: "GooHeader",
    props: {
      layoutStyle: {
        type: String,
        default: "opaque"
      },
      logoUrl: {
        type: String,
        default: "/"
      },
      compact: {
        type: Boolean,
        default: null
      },
      title: {
        type: String,
        default: null
      },
      partnership: {
        type: String,
        default: null
      }
    },
    data () {
      return {
        mobileDropDownActive: false,
        dropDownTransitioning: false
      };
    },
    computed: {
      isTransparent () {
        return !this.mobileDropDownActive && !this.dropDownTransitioning && this.layoutStyle === "transparent";
      },
      hasMobileNav () {
        return !!this.$slots["mobile-nav"];
      },
      className () {
        return {
          "is-transparent": this.isTransparent,
          "is-open": this.mobileDropDownActive,
          "is-compact": this.compact
        };
      }
    },
    mounted () {
      document.addEventListener("click", this.onDocumentClick.bind(this));
    },
    destroyed () {
      document.removeEventListener("click", this.onDocumentClick.bind(this));
    },
    methods: {
      onNavToggleClick () {
        if (!this.mobileDropDownActive) {
          this.openNav();
        } else {
          this.closeNav();
        }
      },
      openNav () {
        this.mobileDropDownActive = true;
      },
      closeNav () {
        this.dropDownTransitioning = true;
        this.mobileDropDownActive = false;
      },
      onAfterDropDownClose () {
        this.dropDownTransitioning = false;
      },
      onDocumentClick (event) {
        if (this.$refs.mobileNav && this.clickedOnLink(this.$refs.mobileNav, event)) {
          this.closeNav();
        }
      },
      clickedOnLink (container, event) {
        let target = event.target;
        while (container.contains(target)) {
          if (target.tagName.toLowerCase() === "a") { // check if target is an anchor tag
            return true;
          }
          target = target.parentNode;
        }
        return false;
      },
      onLogoClick (e) {
        // FIXME: Ideally, we would use a router-link here but the header is used outside the router sometimes
        if (this.$router && this.isValidRoute(this.logoUrl)) {
          e.preventDefault();
          this.$router.push(this.logoUrl);
        }
      },
      isValidRoute (path) {
        const isAbsoluteURL = (typeof path === "string") && /^http(s)?:\/\//i.test(path);
        if (isAbsoluteURL) {
          return false;
        }

        const route = this.$router.resolve(path);
        return route.resolved.matched.length > 0;
      }
    }
  };
</script>
