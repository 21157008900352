import auth from "@/auth";
import ProductAwareMixin from "@/mixins/ProductAwareMixin";

const VALID_DOMAINS = [
  "gooroo.com"
];

const INTENT_ROUTES = {
  "gooroo-pass": {
    name: "gooroo-pass.checkout"
  }
};

function isSafeURL (url) {
  if (url.startsWith("/")) {
    // Relative URL
    return true;
  }

  const parsedURL = new URL(url);

  for (const tld of VALID_DOMAINS) {
    if (parsedURL.hostname.endsWith(tld)) {
      return true;
    }
  }

  return false;
}

export default {
  mixins: [ProductAwareMixin],
  computed: {
    intent () {
      return this.$route.query.intent || "none";
    }
  },
  methods: {
    /**
     * @param fallback {string|null} fallback URL
     * @return {string}
     */
    getSafeNextURL (fallback = null) {
      const next = this.$route.query.next;
      if (next && isSafeURL(next)) {
        return next;
      }

      return fallback || this.product.url;
    },
    handleIntent () {
      const intentRoute = INTENT_ROUTES[this.intent];

      if (intentRoute) {
        if (!auth.isAuthenticated) {
          auth.reset();
        }

        // eslint-disable-next-line no-unused-vars
        const _ = this.$router.push({
          ...intentRoute,
          query: {
            next: this.getSafeNextURL(),
            product: this.product.id
          }
        });
      } else {
        this.redirectToNextURL();
      }
    },
    redirectToNextURL () {
      window.location = this.getSafeNextURL();
    }
  }
};
