<template>
  <div class="goo-header-mobile-links">
    <template v-if="showLinks">
      <div v-for="link in links" :key="link.title" class="goo-header-item">
        <RouterLink :to="{name: link.route}" class="goo-header-mobile-link">
          {{ link.title }}
        </RouterLink>
      </div>
    </template>
    <div class="goo-header-item">
      <RouterLink :to="{ name: 'auth.logout' }" class="goo-header-mobile-link">
        Log out
      </RouterLink>
    </div>
  </div>
</template>

<script>
  const links = require("@/data/nav-links.json");

  export default {
    name: "MobileNav",
    props: {
      showLinks: {
        type: Boolean,
        default: true
      }
    },
    data () {
      return {
        links
      };
    }
  };
</script>

<style lang="scss">

</style>
