const PRODUCTS = {
  none: {
    id: null,
    name: null,
    url: "/"
  },
  courses: {
    id: "courses",
    name: "COURSES",
    url: process.env.VUE_APP_GOOROO_COURSES_URL
  },
  tutoring: {
    id: "tutoring",
    name: "TUTORING",
    url: process.env.VUE_APP_GOOROO_TUTORING_URL
  },
  admin: {
    id: "admin",
    name: "Admin Panel",
    url: process.env.VUE_APP_GOOROO_ADMIN_URL
  }
};

export default {
  computed: {
    product () {
      const identifier = this.$route.query.product || "none";
      return PRODUCTS[identifier] || PRODUCTS.none;
    }
  }
};
