<template>
  <GooHeader :logo-url="link" :title="productName" :partnership="partnership">
    <nav slot="right" class="goo-header-right">
      <GooDropdown v-if="user" position="is-right" class="goo-header-item is-dropdown hide-large-down" :hoverable="true">
        <a slot="toggle" href="#" class="goo-header-link" @click.prevent>
          {{ user.firstName }} <goo-icon name="triangle-down" />
        </a>
        <GooDropdownItem>
          <RouterLink :to="{ name: 'auth.logout' }">
            Log out
          </RouterLink>
        </GooDropdownItem>
      </GooDropdown>
    </nav>
    <template v-if="user" slot="mobile-nav">
      <MobileNav :show-links="showLinks" />
    </template>
  </GooHeader>
</template>

<script>
  import MobileNav from "@/components/Settings/components/MobileNav";
  import GooHeader from "@/components/Auth/GooHeader.vue";

  export default {
    name: "Nav",
    components: { MobileNav, GooHeader },
    props: {
      user: {
        type: Object,
        default: null
      },
      link: {
        type: [Object, String],
        default: "/"
      },
      productName: {
        type: String,
        default: null
      },
      showLinks: {
        type: Boolean,
        default: true
      },
      partnership: {
        type: String,
        default: null
      }
    }
  };
</script>

<style lang="scss">

</style>
